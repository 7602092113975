import { useEffect, useState } from "react";
import { addRemoveClassBody } from "../../utils";

import AboutOurCollection from "../../components/AboutOurCollection";
import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/OurRoadmap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import FindSatoshi from "../../components/FindSatoshi";
import UnveilingSatoshi from "../../components/Unveiling";
// import discord from "../../assets/img/icons/discord.svg";
// import twitter from "../../assets/img/icons/twitter.svg";
// import twitter from "../../assets/img/icons/twitter new logo white 1.svg";
import twitter from "../../assets/img/icons/X white.svg";
import telegram from "../../assets/img/icons/telegram.svg";
// import insta from "../../assets/img/icons/insta.svg";
// import linkedIn from "../../assets/img/icons/linkedIn.svg";
import { useHistory } from "react-router-dom";
import HeroHeader from "../../components/FBI/HeroHeader";
import Modal from "../../components/Modal";
import wib_text from "../../assets/img/wib_peoople_text.svg";
import wib_img from "../../assets/img/wib_people.png";
const HomeDemo1Container = () => {
  const navigate = useHistory();
  const [show, setShow] = useState(false);
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  const handleTwitter = () => {
    navigate("/abc");
  };

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo1.WelcomeArea} />

      {/* <OurBrands data={data[0].OurBrands} /> */}
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <HowItWorks
        ClassItem="how-steps section-padding-0-40"
        data={data[0].HowItWorks}
      /> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "80px 0px",
          gap: "50px",
          background: "#0A1A2E",
          padding: "50px 20px 0px 20px",
        }}
      >
        <img src={wib_text} style={{ width: "50%" }} />
        <img src={wib_img} style={{ width: "90%" }} />
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row text-center d-flex justify-content-center">
          <div className="col-12 col-lg-4 col-md-6 ">
            <div className="text-center">
              {/* Copywrite */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
                className="text-white social-img"
                data-wow-delay="0.4s"
              >
                Follow us at :
                <a
                  href="https://twitter.com/whoisbtcsatoshi"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={twitter} alt="Twitter" width="53px" />
                </a>
                <a
                  href="https://t.me/Indexx_ai"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={telegram} alt="Twitter" width="40px" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "300px" }}></div>

      <HeroHeader />
      {/* <div style={{ marginTop: "300px" }}></div> */}

      <UnveilingSatoshi />

      {/* <div style={{ marginTop: "200px" }}></div> */}

      <OurRoadmap data={data[0].OurRoadmap} />

      <div style={{ marginTop: "200px" }}></div>

      <FindSatoshi />
      <div style={{ marginTop: "200px" }}></div>

      <DiscoverOurNewCollection data={data[0].DiscoverOurNewCollection1} />
      <div style={{ marginTop: "200px" }}></div>

      <DiscoverOurNewCollection data={data[0].DiscoverOurNewCollection2} />

      <div style={{ marginTop: "150px" }}></div>
      <FAQ data={data[0].FAQ} />
      <div style={{ marginTop: "250px" }}></div>

      <TeamArea data={data[0].TeamArea} setShow={setShow} />
      <div style={{ marginTop: "250px" }}></div>

      <Subscribe data={data[0].Subscribe} />
      <div style={{ marginTop: "250px" }}></div>

      <ContactArea data={data[0].ContactArea} />
      <div style={{ marginTop: "250px" }}></div>

      <Modal show={show} onClose={() => setShow(false)} />
    </>
  );
};

export default HomeDemo1Container;
