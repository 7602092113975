import React from "react";
import styled from "styled-components";
import wibLogo from "../../../assets/img/fbi/WIBS_logo.svg";
import wibText from "../../../assets/img/fbi/wibsTexts.svg";
import LinkBg from "../../../assets/img/coin/Link.png";
const Container = styled.div`
  max-width: 1125px;
  margin: 100px auto 250px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 60%; /* 50% width for desktop */
  padding: 20px;
`;

const ContentContainer = styled.div`
  flex: 0 0 40%; /* 50% width for desktop */
  padding: 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

const HotSale = () => {
  return (
    <Container>
      <LogoContainer>
        {/* Your logo or image goes here */}
        <img src={wibLogo} alt="Logo" />
      </LogoContainer>
      <ContentContainer>
        <img src={wibText} alt="Logo" />

        <a
          href="https://cex.indexx.ai/update/home?buyToken=WIBS"
          className="btn more-btn "
          style={{ backgroundImage: `url(${LinkBg})` }}
        >
          Buy Now
        </a>
      </ContentContainer>
    </Container>
  );
};

export default HotSale;
