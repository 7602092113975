import React, { useState, useEffect } from "react";
import "./style.css";

const TimerCountDown = () => {
  const deadline = new Date("2024-11-01T00:00:00-07:00");
  //   const currentTime = new Date();

  //   // Add 1 minute to the current time
  //   const deadline = new Date(currentTime.getTime() + 10000);
  const calculateTimeLeft = () => {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div>
      {days === undefined &&
      hours === undefined &&
      minutes === undefined &&
      seconds === undefined ? (
        <h1>Timer Expired</h1>
      ) : (
        <div className="time-box-wrapper">
          {days !== undefined && (
            <div className="single-box">
              <h1>{days}</h1>
              <p>Days</p>
            </div>
          )}

          <span style={{ fontWeight: "bold" }}>:</span>
          {hours !== undefined && (
            <div className="single-box">
              <h1>{hours}</h1>
              <p>Hours</p>
            </div>
          )}

          <span style={{ fontWeight: "bold" }}>:</span>
          {minutes !== undefined && (
            <div className="single-box">
              <h1>{minutes}</h1>
              <p>Minutes</p>
            </div>
          )}

          <span style={{ fontWeight: "bold" }}>:</span>
          {seconds !== undefined && (
            <div className="single-box">
              <h1>{seconds}</h1>
              <p>Seconds</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimerCountDown;
