import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = 12000;
const supportedChainIds = [1]; // 1 is the chain ID for Ethereum Mainnet

export const injected = new InjectedConnector({ supportedChainIds });

export const coinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/847e89cb852c43048f143456f82c32ec`, // Replace with your Infura project ID
  appName: "YourAppName",
  supportedChainIds,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: `https://mainnet.infura.io/v3/847e89cb852c43048f143456f82c32ec`, // Replace with your Infura project ID
  },
  appName: "YourAppName",
  supportedChainIds,
  pollingInterval: POLLING_INTERVAL,
});


export const switchNetwork = async (provider) => {
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // '0x1' is the chain ID for Ethereum Mainnet
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask
    if (switchError.code === 4902) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: '0x1',
            chainName: 'Ethereum Mainnet',
            rpcUrls: ['https://mainnet.infura.io/v3/847e89cb852c43048f143456f82c32ec'], // Replace with your Infura project ID
            blockExplorerUrls: ['https://etherscan.io'],
            nativeCurrency: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: 18,
            },
          }],
        });
      } catch (addError) {
        console.error(addError);
      }
    } else {
      console.error(switchError);
    }
  }
};