import { NavLink } from "react-router-dom";
import first from "../../../assets/img/hero-header/coin.png";
import second from "../../../assets/img/hero-header/ordinal.png";

// import third from "../../../assets/img/hero-header/NFT.png";
import third from "../../../assets/img/hero-header/new NFT graphic.png";
import top from "../../../assets/img/hero-header/top.svg";
import coinUnderline from "../../../assets/img/hero-header/coinUnderline.png";
const WelcomeArea = ({ data }) => {
  return (
    <section
      className="hero-section-home"
      id="home"
      // style={{ border: "1px solid red" }}
    >
      {/* Hero Content */}
      <div className="app">
        <div className="row align-items-center">
          {/* Welcome Content */}
          <div className="col-12 col-lg-12 col-md-12">
            <div className="text-center" style={{ marginTop: "200px" }}>
              {/* Hero Content */}
              <div className="mb-50 mt-9">
                <img
                  src={top}
                  alt="Rectangular Image"
                  width={750}
                  height={550}
                  className="mx-auto mb-4"
                />
              </div>

              {/* Cards Section */}
              <div
                className="row justify-content-center"
                style={{
                  marginTop: "70px",
                  marginRight: "-15px",
                  marginLeft: "-15px",
                }}
              >
                <div className="col-md-4 mb-4">
                  <NavLink to="/coin" className="cursor-pointer">
                    <div className="header-card">
                      <img src={first} alt="Card 1 Image" />
                      <p>Token</p>
                      <div style={{ height: "30px" }}>
                        <img src={coinUnderline} style={{ height: "30px" }} />
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 mb-4">
                  <NavLink to="/ordinals" className="cursor-pointer">
                    <div className="header-card">
                      <img src={second} alt="Card 2 Image" />
                      <p>Ordinal</p>
                      <div
                        style={{
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        Coming Soon
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 mb-4">
                  <NavLink to="/nft" className="cursor-pointer">
                    <div className="header-card">
                      <img src={third} alt="Card 3 Image" />
                      <p>NFT</p>
                      <div
                        style={{
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        Coming Soon
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
